<template>
  <v-card class="mt-4">
    <v-card-title>Notes</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12">
          <v-card
            v-for="(note, index) in user.sticky_notes"
            :key="index"
            class="d-flex justify-end mb-3 mx-3"
          >
            <v-card-text class="d-flex justify-space-between mb-3 mx-3 mr-0">
              <pre class="notetext">{{ note }}</pre>
              <v-icon
                class="noteicon"
                @click="deleteNote(index)"
                slot="append"
                color="error"
              >
                mdi-minus-circle-outline
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="12">
          <div id="sticky_notes">
            <v-textarea
              class="mx-3"
              outlined
              v-model="newNote"
              name="input-7-4"
              label="Add Note"
              value=""
              ><v-icon @click="addNote" slot="append" color="primary">
                mdi-plus-circle-outline
              </v-icon>
            </v-textarea>
            <v-spacer></v-spacer>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.notetext {
  font-family: "Roboto", sans-serif;
  white-space: pre-wrap;
}
.noteicon {
  position: relative;
  top: 5px;
  right: 0px;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "UserCardNotes",
  mixins: [hasuraService],
  props: {
    user: Object,
  },
  data() {
    return {
      newNote: "",
      notes: [],
      valid: true,
    };
  },
  methods: {
    deleteNote(index) {
      this.user.sticky_notes.splice(index, 1);
      this.update_user_sticky_by_pk(this.user.id, this.user.sticky_notes);
    },
    addNote() {
      if (this.newNote !== "") {
        if (this.user.stick_notes == null) {
          this.user.sticky_notes = [];
        }
        this.user.sticky_notes.push(this.newNote);
        this.update_user_sticky_by_pk(this.user.id, this.user.sticky_notes);
      }
      this.newNote = "";
    },
  },
};
</script>
