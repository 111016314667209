var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3 ma-2",attrs:{"headers":_vm.headers,"items":_vm.agent_data_filtered,"sort-by":"name","loading":_vm.loading,"search":_vm.search,"single-expand":true,"expanded":_vm.expanded,"item-key":_vm.index,"items-per-page":-1,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Agents")]),(_vm.filter_menu)?_c('v-spacer'):_vm._e(),(_vm.filter_menu)?_c('v-switch',{staticClass:"mr-2 mt-5",attrs:{"label":"44c Mail"},model:{value:(_vm.bootcamp),callback:function ($$v) {_vm.bootcamp=$$v},expression:"bootcamp"}}):_vm._e(),(_vm.filter_menu)?_c('v-switch',{staticClass:"mr-2 mt-5",attrs:{"label":"Hidden"},model:{value:(_vm.hidden),callback:function ($$v) {_vm.hidden=$$v},expression:"hidden"}}):_vm._e(),(_vm.filter_menu)?_c('v-text-field',{staticClass:"ml-2",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)]},proxy:true},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNumber(item.phone))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('user-card',{attrs:{"user_id":item.id}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }