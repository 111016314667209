<template>
  <v-data-table
    :headers="headers"
    :items="agent_data_filtered"
    sort-by="name"
    class="elevation-3 ma-2"
    :loading="loading"
    :search="search"
    :single-expand="true"
    :expanded.sync="expanded"
    :item-key="index"
    :items-per-page="-1"
    show-expand
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Agents</v-toolbar-title>
        <v-spacer v-if="filter_menu"></v-spacer>
        <!-- <v-switch
          v-if="filter_menu"
          class="mr-2 mt-5"
          v-model="approved"
          label="Approved"
        ></v-switch> -->
        <v-switch
          v-if="filter_menu"
          class="mr-2 mt-5"
          v-model="bootcamp"
          label="44c Mail"
        ></v-switch>
        <v-switch
          v-if="filter_menu"
          class="mr-2 mt-5"
          v-model="hidden"
          label="Hidden"
        ></v-switch>
        <v-text-field
          v-if="filter_menu"
          class="ml-2"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          clearable
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <!-- eslint-disable-next-line
    <template v-slot:item.all_in="{ item }">
      <v-icon v-if="item.all_in" color="success">
        mdi-check
      </v-icon>
      <v-icon v-else color="error">
        mdi-close
      </v-icon>
    </template> -->
    <!-- eslint-disable-next-line -->
    <!-- <template v-slot:item.approved="{ item }">
      <v-icon v-if="item.approved" color="success">
        mdi-check
      </v-icon>
      <v-icon v-else color="error">
        mdi-close
      </v-icon>
    </template> -->
    <!-- eslint-disable-next-line -->
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.phone="{ item }">
      {{ formatPhoneNumber(item.phone) }}
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <user-card :user_id="item.id"></user-card>
      </td>
    </template>
  </v-data-table>
</template>

<style scoped>
.v-text-field {
  max-width: 400px;
}
</style>

<script>
import UserCard from "../../components/Admin/UserCard.vue";
import dayjs from "dayjs";

export default {
  name: "AgentTable",
  components: { UserCard },
  props: {
    index: {
      type: String,
      default: "id",
    },
    agent_data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filter_menu: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    expanded: [],
    search: "",
    bootcamp: false,
    hidden: false,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      { text: "Phone", value: "phone" },
      { text: "Status", value: "status" },
      // { text: "44c Mail", value: "all_in" },
      { text: "Carriers", value: "allowed_carriers_cnt" },
      { text: "Campaigns", value: "campaign_cache" },
      { text: "Marketer", value: "marketer_clean" },
      // { text: "Approved", value: "approved" },
      // { text: "12 Months Production", value: "annuity_premium" },
    ],
  }),
  computed: {
    agent_data_filtered: function() {
      return this.agent_data.filter(() => {
        return true;
        // if (!this.filter_menu) {
        //   return true;
        // }
        // if (this.bootcamp && !agent.all_in) {
        //   return false;
        // }
        // if (this.approved && !agent.approved) {
        //   return false;
        // }
        // return this.hidden ? agent.hidden : !agent.hidden;
      });
    },
  },

  methods: {
    formatPhoneNumber(phoneNumberString) {
      if (!phoneNumberString) {
        return "";
      }
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return phoneNumberString;
    },
    formatDate(date) {
      return dayjs(date).format("MM/DD/YYYY");
    },
    handleClick: function(e) {
      this.$router.push(`/admin/agents/${e.id}`);
    },
  },
};
</script>
