<template>
  <v-card elevation="4" max-width="800" class="mt-4 mb-4 mx-auto">
    <v-tabs v-model="tab" show-arrows>
      <v-tabs-slider></v-tabs-slider>

      <v-tab v-for="(item, index) in tab_items" :key="index">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-card-text>
      <v-card v-if="tab == 0" elevation="1">
        <v-card-title>Agent Details</v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.id"
                  label="User ID"
                  dense
                  :loading="loading"
                  :disabled="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.name"
                  label="Name"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.email"
                  label="Email"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.phone"
                  label="Phone"
                  type="number"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.street"
                  label="Street"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.city"
                  label="City"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="user.state"
                  :items="all_states"
                  label="State"
                  item-value="abbreviation"
                  item-text="name"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.zip"
                  label="Zip"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="user.marketer_id"
                  :items="marketers"
                  label="Marketer"
                  item-value="id"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" sm="6">
                <v-select
                  v-model="user.annuity_premium"
                  :items="[
                    'Less than $5,000,000 Premium',
                    'Between $5,000,000 and $10,000,000 Premium',
                    'Over $10,000,000 Premium',
                  ]"
                  label="Annuity Premium Written: Last 12 months"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select>
              </v-col> -->

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.current_imos"
                  label="Current IMOs"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  v-model="user.status"
                  :items="[
                    'Registered',
                    'Active',
                    'Inactive',
                    'Not Interested',
                  ]"
                  label="Status"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select>
              </v-col>

              <!-- <v-col cols="12" sm="6">
                <v-select
                  v-model="user.bootcamp_status"
                  :items="['No', 'Registered', 'Attended']"
                  label="Bootcamp Status"
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-select>
              </v-col> -->
              <!-- 
              <v-col cols="12" sm="4">
                <v-switch
                  :loading="loading"
                  :disabled="loading"
                  v-model="user.approved"
                  label="Approved"
                ></v-switch>
              </v-col> -->

              <!-- <v-col cols="12" sm="4">
                <v-switch
                  :loading="loading"
                  :disabled="loading"
                  v-model="user.all_in"
                  label="44c Mail"
                ></v-switch>
              </v-col> -->

              <v-col cols="12" sm="4">
                <v-switch
                  :loading="loading"
                  :disabled="loading"
                  v-model="user.hidden"
                  label="Hidden"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="btn-fix"
                  >
                    Delete Account
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    Are you sure?
                  </v-card-title>

                  <v-card-text>
                    Deleting a user can not be undone. Any saved reports,
                    seminars, households they have will be deleted too.
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn color="primary" text @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="deleteUser">
                      I accept
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-spacer></v-spacer>

              <v-btn
                :loading="loading"
                :disabled="loading"
                class="mr-3 mb-3"
                @click="updateUser"
                color="primary"
              >
                Update
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <user-card-notes v-if="tab == 1" :user="user"></user-card-notes>
      <!-- <proposal-table v-if="tab == 2" :user_id="user_id"></proposal-table> -->
      <!-- <user-card-carriers
        v-if="tab == 3 && user"
        :user_id="user.id"
      ></user-card-carriers> -->
      <user-card-seminar
        v-if="tab == 4 && user"
        :user_id="user.id"
      ></user-card-seminar>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>

<script>
// import ProposalTable from "../Shared/ProposalTable.vue";
import hasuraService from "@/services/hasura.service.js";
import UserCardSeminar from "./UserCardSeminar.vue";
// import UserCardCarriers from "./UserCardCarriers.vue";
import UserCardNotes from "./UserCardNotes.vue";

export default {
  name: "AdminUserCard",
  mixins: [hasuraService],
  props: {
    user_id: {
      type: Number,
      required: true,
    },
  },
  components: {
    // ProposalTable,
    UserCardSeminar,
    // UserCardCarriers,
    UserCardNotes,
  },
  async mounted() {
    this.setup_user();
    this.get_marketers().then((marketers) => {
      this.marketers = marketers;
    });
  },
  watch: {
    user_id() {
      this.setup_user();
    },
  },

  methods: {
    setup_user: async function() {
      this.loading = true;
      this.user = await this.get_user_by_pk(this.user_id);
      this.loading = false;
    },
    deleteUser: async function() {
      this.loading = true;
      this.dialog = false;
      await this.delete_user_by_pk(this.user_id);
      this.$store.commit("set_snackbar", "Account Deleted.");
      this.loading = false;
    },
    updateUser: async function() {
      this.loading = true;
      await this.update_user_by_pk(this.user_id, this.user);
      this.$store.commit("set_snackbar", "Saved!");
      this.loading = false;
    },
  },

  data() {
    return {
      tab: 0,
      valid: true,
      dialog: false,
      marketers: [],
      loading: false,
      user: {},
      all_states: [
        { name: "ALABAMA", abbreviation: "AL" },
        { name: "ALASKA", abbreviation: "AK" },
        { name: "ARIZONA", abbreviation: "AZ" },
        { name: "ARKANSAS", abbreviation: "AR" },
        { name: "CALIFORNIA", abbreviation: "CA" },
        { name: "COLORADO", abbreviation: "CO" },
        { name: "CONNECTICUT", abbreviation: "CT" },
        { name: "DELAWARE", abbreviation: "DE" },
        { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
        { name: "FLORIDA", abbreviation: "FL" },
        { name: "GEORGIA", abbreviation: "GA" },
        { name: "HAWAII", abbreviation: "HI" },
        { name: "IDAHO", abbreviation: "ID" },
        { name: "ILLINOIS", abbreviation: "IL" },
        { name: "INDIANA", abbreviation: "IN" },
        { name: "IOWA", abbreviation: "IA" },
        { name: "KANSAS", abbreviation: "KS" },
        { name: "KENTUCKY", abbreviation: "KY" },
        { name: "LOUISIANA", abbreviation: "LA" },
        { name: "MAINE", abbreviation: "ME" },
        { name: "MARYLAND", abbreviation: "MD" },
        { name: "MASSACHUSETTS", abbreviation: "MA" },
        { name: "MICHIGAN", abbreviation: "MI" },
        { name: "MINNESOTA", abbreviation: "MN" },
        { name: "MISSISSIPPI", abbreviation: "MS" },
        { name: "MISSOURI", abbreviation: "MO" },
        { name: "MONTANA", abbreviation: "MT" },
        { name: "NEBRASKA", abbreviation: "NE" },
        { name: "NEVADA", abbreviation: "NV" },
        { name: "NEW HAMPSHIRE", abbreviation: "NH" },
        { name: "NEW JERSEY", abbreviation: "NJ" },
        { name: "NEW MEXICO", abbreviation: "NM" },
        { name: "NEW YORK", abbreviation: "NY" },
        { name: "NORTH CAROLINA", abbreviation: "NC" },
        { name: "NORTH DAKOTA", abbreviation: "ND" },
        { name: "OHIO", abbreviation: "OH" },
        { name: "OKLAHOMA", abbreviation: "OK" },
        { name: "OREGON", abbreviation: "OR" },
        { name: "PALAU", abbreviation: "PW" },
        { name: "PENNSYLVANIA", abbreviation: "PA" },
        { name: "PUERTO RICO", abbreviation: "PR" },
        { name: "RHODE ISLAND", abbreviation: "RI" },
        { name: "SOUTH CAROLINA", abbreviation: "SC" },
        { name: "SOUTH DAKOTA", abbreviation: "SD" },
        { name: "TENNESSEE", abbreviation: "TN" },
        { name: "TEXAS", abbreviation: "TX" },
        { name: "UTAH", abbreviation: "UT" },
        { name: "VERMONT", abbreviation: "VT" },
        { name: "VIRGIN ISLANDS", abbreviation: "VI" },
        { name: "VIRGINIA", abbreviation: "VA" },
        { name: "WASHINGTON", abbreviation: "WA" },
        { name: "WEST VIRGINIA", abbreviation: "WV" },
        { name: "WISCONSIN", abbreviation: "WI" },
        { name: "WYOMING", abbreviation: "WY" },
      ],
      tab_items: [
        "Agent Details",
        "Notes",
        // "Proposals",
        "Carriers",
        "Seminars",
      ],
    };
  },
};
</script>
